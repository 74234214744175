p {
  margin-bottom: 0;
}

.main-container,
.checkout-container {
  display: block;
  margin: 0.5rem auto;
  width: 600px;
}

.main-container,
.checkout-container,
.address-container,
#protectionForm,
.successful-container .btn,
.declined-container .btn,
.back-to-login,
.popup {
  position: relative;
}

.loading-icon,
.protection-container,
#readOnlyField,
.successful-container .btn > span,
.declined-container .btn > span,
.popup svg {
  position: absolute;
}

.loading-icon {
  left: 0;
  margin: 0 auto;
  right: 0;
  top: 5rem;
  width: 95px;
  z-index: 120;
}

.logo-img {
  display: block;
  margin: 0 auto 2.5rem;
  width: 385px;
}

input {
  color: rgb(48, 49, 61);
  font-size: inherit;
}

button[type="submit"] {
  cursor: pointer;
  margin-top: 1rem;
  padding: 0.375rem 0.75rem;
}

.error-message,
.login-error-message,
.declined-title,
.back-to-checkout {
  color: #dc3545;
}

.error-message,
.successful-title,
.declined-title,
.back-to-login > a,
.back-to-checkout {
  font-weight: 600;
}

.error-message {
  margin-top: 1rem;
}

.p-FieldError.Error {
  margin-top: 0.75rem !important;
}

.form-billingDetails .top-section {
  display: flex;
  justify-content: space-between;
}

.form-billingDetails .top-section > div > input {
  width: 48%;
}

.separator {
  margin-top: 0.4rem;
}

.form-title,
.error-message,
.custom-field-container > label,
input {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.form-title,
.error-message {
  font-size: 1.35rem;
  text-transform: uppercase;
}

.form-title {
  font-weight: bold;
  margin: 2rem 0 1rem;
}

.custom-field-container {
  margin-bottom: 0.77rem;
}

.protection-container label,
.custom-field-container label {
  align-items: baseline;
  color: rgb(48, 49, 61);
  display: flex;
  font-size: 0.93rem;
  justify-content: space-between;
  margin-bottom: 4px;
}

#customField {
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
  font-size: 16px;
  padding: 9px;
}

#customField::-webkit-outer-spin-button,
#customField::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#protectionForm .protection-page-section > input:focus,
#customField:focus {
  border-color: hsla(210, 96%, 45%, 50%);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.03), 0 3px 6px rgba(0, 0, 0, 0.02);
  outline-color: hsla(210, 96%, 45%, 50%);
}

#protectionForm svg {
  background: rgb(238, 238, 238);
  border-radius: 80%;
  border: 1px solid #999;
  height: 3.25em;
  margin-top: -4.4rem;
  padding: 1.25em;
}

.protection-container {
  display: block;
  height: 100%;
  z-index: 150;
}

.protection-container {
  top: 7rem;
}

.checkout-container {
  top: 0;
}

.protection-container.authenticated {
  display: none;
}

#protectionForm {
  background: rgb(238, 238, 238);
  border-radius: 6px;
  border: 1px solid #999;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  left: 0;
  margin: 0 auto;
  padding: 1.25rem 3rem 2.5rem;
  right: 0;
  width: 370px;
  z-index: 150;
}

#customField,
.protection-container,
.btn.btn-success.protection-btn,
.p-LinkInput,
#protectionForm .protection-page-section > input,
#protectionForm .protection-page-section {
  width: 100%;
}

#protectionForm .protection-page-section > input {
  border-color: rgb(200, 200, 200) !important;
  border-radius: 5px;
  border: 1px solid;
  padding: 0.5rem;
}

#protectionForm .protection-page-section {
  margin-top: 1rem;
}

.protection-page-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.protection-form-title,
.login-error-message,
#submitButton,
.successful-container,
.declined-container {
  text-align: center;
}

.protection-form-title,
.amount-message {
  font-weight: bold;
}

.protection-form-title {
  font-size: 24px;
  margin: 0.75rem 0 0.4rem;
}

.login-error-message {
  font-size: 0.93rem;
  margin-top: 1rem;
}

.login-error-message.hide-message {
  display: none;
}

.amount-message {
  float: right;
  font-size: 0.7rem;
}

.btn.btn-success,
.btn.btn-danger {
  border-radius: 5px;
  color: #fff;
  font-size: 1.33rem;
  font-weight: 500;
  line-height: 1.5;
  margin: 1.5rem 0 0;
  padding: 0.5rem 1.15rem;
  text-decoration: none;
}

input.btn-success.disabled,
input.btn-success:disabled {
  background: #73b582;
  border-color: #73b582;
}

input.btn.btn-success.protection-btn {
  margin-top: 1.5rem;
}

.p-LinkInput {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#submitButton {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  background: #28a745;
  border-color: #28a745;
  border: 1px solid transparent;
  color: #fff;
  display: inline-block;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

#customField:read-only,
#readOnlyField {
  background: rgba(238, 238, 238, 0.5);
}

#customField:read-only {
  color: rgb(184, 184, 184);
}

#readOnlyField,
#customField:read-only {
  cursor: not-allowed;
}

#readOnlyField {
  bottom: 0;
  height: 44.39px;
  left: 0;
  pointer-events: stroke;
  right: 0;
  top: 1.5rem;
  z-index: 150;
}

#protectionForm .protection-page-section > input:focus,
#customField:focus {
  border-color: #e6e6e6;
  box-shadow: 0 0 0;
  outline-width: 0;
  outline-color: #e6e6e6;
}

.successful-container .btn,
.declined-container .btn {
  margin-top: 2rem;
  padding: 0.5rem 2.5rem 0.5rem 1rem;
}

.successful-container .btn > span,
.declined-container .btn > span {
  font-size: 2rem;
  right: 0.66rem;
  top: 0;
}

.declined-title,
.successful-title {
  font-size: 1.725rem;
  margin: 0;
}

.successful-title,
.back-to-login > a {
  color: #28a745;
}

.declined-title,
.back-to-payment > a {
  color: #dc3545;
}

.declined-title {
  line-height: 110%;
}

.back-to-login {
  display: inline-block;
}

.success-payment-id,
.declined-payment-id {
  color: rgb(48, 49, 61);
  font-size: 1.2rem;
  margin-top: 0.65rem;
}

.popup {
  background: rgba(255, 255, 255, 0.5);
  left: 0;
  margin: 5rem auto 0;
  right: 0;
  width: 625px;
  z-index: 1000;
}

.popup svg {
  background: #28a745;
  border-radius: 50%;
  color: #fff;
  font-size: 3rem;
  left: 0;
  margin: 0 auto;
  padding: 20px;
  right: 0;
  z-index: 200;
}

.declined-popup.popup svg,
.success-popup.popup svg {
  top: -12%;
}

.declined-popup svg {
  background: #dc3545;
  padding: 20px 25px;
}

.successful-container,
.declined-container {
  background: rgb(238, 238, 238);
  border-radius: 9px;
  border-top: 25px solid #9fdbac;
  border: 1px solid #999;
  overflow: hidden;
  padding: 60px 10px 35px;
  width: 100%;
}

.declined-container {
  border-top: 25px solid #f89ca5;
}
